.header {
  padding-bottom: 1rem;
}

.clock {
  font-size: 0.8rem;
}

@media (min-width: 576px) {
  .clock {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
