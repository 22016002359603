.not-found {
    padding-top: 100px;
    text-align: center;
  }

  .handling-request {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    background-color: white;
    color: #212222;
    font-family: Barlow Condensed,sans-serif;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(33,34,34,0.5);
    -moz-box-shadow: 0px 0px 15px 3px rgba(33,34,34,0.5);
    box-shadow: 0px 0px 15px 3px rgba(33,34,34,0.5);
  }