.custom-control {
  margin: 0.25rem 0;
  padding: 0.2rem 1.25rem;
  padding-left: 2rem;
  display: block !important;

  &.overflow-checkbox {
    .overflow-control-input {
      display: none;

      &:checked ~ .overflow-control-indicator {
        &::after {
          transform: rotateZ(45deg) scale(1);
          top: -6px;
          left: 5px;
        }

        &::before {
          opacity: 1;
        }
      }
    }

    .overflow-control-indicator {
      //   border-radius: 3px;
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $color-accent;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        transition: 0.3s;
        transform: rotateZ(90deg) scale(0);
        width: 10px;
        border-bottom: 4px solid $color-accent;
        border-right: 4px solid $color-accent;
        border-radius: 3px;
        top: -4px;
        left: 4px;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        transition: 0.3s;
        width: 10px;
        border-right: 7px solid #fff;
        border-radius: 3px;
        transform: rotateZ(45deg) scale(1);
        top: -4px;
        left: 5px;
        opacity: 0;
      }
    }

    .overflow-control-description {
      color: black;
    }

    .overflow-control-indicator,
    .overflow-control-description {
      cursor: pointer;
    }
  }
}
