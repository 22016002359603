main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  line-height: 1.6;
  height: 100%;
  min-height: 100vh;
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

// img {
//   width: 100%;
// }

#root {
  height: 100vh;
}
