.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.dropdown-menu {
  border-color: #85714d !important;
  border-width: 0.1rem !important;
  border-style: solid !important;
  background-color: #85714d;
  color: white !important;
  box-shadow: 0px 10px 30px 0px rgba(255,255,255,1);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: white !important;
}

.dropdown-item {
  color: white !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #85714d !important;
  background-color: white !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #85714d;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs {
  color: #85714d !important;
  background-color: transparent;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #aaa;
  font-size: 2rem;
}
.nav-tabs .nav-link:hover {
  color: #000 !important;
}
.nav-tabs .nav-link.active:hover {
  color: #fff !important;
}

.tab-content {
  background-color: #fff !important;
}
