.handling-request-wizard {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  background-color: #ddd;

  @media screen and (min-width: 768px) {
    padding: 30px;
  }
}

.handling-request-wizard__wrapper {
  background-color: #fff;
  margin: 0;
  padding: 10px;

  @media screen and (min-width: 768px) {
    margin: auto;
    max-width: 768px;
  }
}

.handling-request-wizard__header {
  padding: 0;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (min-width: 400px) {
    flex-direction: row;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 700;
    margin: auto;

    @media screen and (min-width: 768px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  .logo {
    width: 50%;
    height: 35px;
    margin: auto;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
    background-size: contain;
    display: block;
    background-image: url(../shared/img/logo/logo_light.svg);

    // @include themify($themes) {
    //   background-image: themed("logoImg");
    // }

    @media screen and (min-width: 768px) {
      margin-right: 0;
    }
  }
}

.handling-request-wizard__content {
  height: 70vh;
}

.handling-request-wizard__form-section__header {
  text-align: center;
  font-size: 1.75em;
  font-weight: 600;
  padding: 0 !important;
  border-bottom: thin solid black;
  margin: 5px 0;

  @media screen and (min-width: 768px) {
    margin: 10px 0;
  }
}

.handling-request-wizard__form {
  padding: 10px;
  padding-top: 0;
  flex-grow: 1;

  @media screen and (min-width: 768px) {
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .handling-request-wizard__toolbar {
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .previous {
      @media screen and (max-width: 768px) {
        margin: 10px 0 5px 0;
        width: 100%;
      }
    }

    .next {
      margin-left: auto;
      @media screen and (max-width: 768px) {
        margin: 10px 0 5px 0;
        width: 100%;
      }
    }
  }
}

.handling-request-wizard__form-section__title {
  font-size: 20px;
  font-weight: 600;
  padding: 0 !important;
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  font-family: "Barlow", sans-serif;

  @media screen and (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  a,
  a:visited {
    color: $color-accent;
  }

  & small {
    margin-left: 0;
    margin-top: auto;
    font-size: 0.75em;
    color: $color-additional;

    @media screen and (min-width: 768px) {
      margin-left: auto;
    }
  }

  &.md-row {
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .description {
    font-size: 0.8em;
    color: $color-additional;
    @media screen and (min-width: 768px) {
      font-size: 1em;
    }
  }
}

.handling-request-wizard__steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.handling-request-wizard__step {
  text-align: center;
  height: 38px;
  width: 38px;
  display: flex;
  transition: background 0.3s;
  margin-left: 10px;
  border: 1px solid $color-accent;

  &:first-child {
    margin-left: 0;
  }

  &:before {
    border-top: black thin solid;
    width: 50px;
    height: 5px;
  }

  // @include themify($themes) {
  //   border: 1px solid themed("colorBorder");
  //   background: themed("colorHover");
  // }
  border: 1px solid #646777;
  background: #fafbfe;

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
  }

  &.handling-request-wizard__step--active {
    background: $color-accent;
    border-color: $color-accent;

    p {
      color: #ffffff;
    }
  }
}

.handling-request-wizard__form-wrapper {
  display: flex;
  justify-content: center;
}

.handling-request-wizard__title {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.handling-request-wizard__description {
  color: $color-additional;
  margin: 0;
  max-width: 410px;
}

.handling-request-wizard__form-group {
  border-radius: 0 !important;
  margin-top: 1rem;
}

.react-phone-number-input__phone {
  font-size: 1em !important;
  font-weight: bold;
  color: black;
}

.form-control.handling-request-wizard__form-control {
  border-radius: 0 !important;
  border: none;
  border-bottom: thin solid;
  border-color: $color-accent !important;
  font-size: 1em !important;
  font-weight: bold;
  color: black;

  &::placeholder,
  .react-phone-number-input__phone::placeholder {
    color: $color-additional;
    font-weight: normal;
    font-family: "Barlow Condensed", sans-serif;
  }

  &:hover,
  &:focus {
    // @include themify($themes) {
    //   color: themed("colorText");
    // }
    // background-color: #fff;
    // border-color: #80bdff;
    outline: 0;
    // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    box-shadow: none !important;
  }
}

.handling-request-wizard__form-control-textarea {
  line-height: 24px;
  resize: none;
}

.segmented {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  margin: 0 !important;
  margin-left: 10px !important;
  padding: 0;
  border: none;
  border-radius: 0;
}

.segmented > button {
  display: block;
  // flex: 1;
  width: 38px;
  border-right: none;
  // @include themify($themes) {
  //   color: themed("colorText");
  // }
  color: #2f2f2f;
  border: 1px solid $color-accent;
  // margin: auto;
  padding: 0.5em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  background-color: #fff !important;
  transition: all 250ms ease-in-out;

  &.segment-selection {
    background-color: $color-accent !important;
    color: #fff !important;

    &:focus,
    &:active {
      background-color: $color-accent !important;
      color: #fff !important;
    }
  }
}

.handling-request-wizard__form-date-picker {
  .react-datepicker,
  .react-datepicker__header,
  .react-datepicker__time-container {
    border-color: $color-accent;
  }

  .react-datepicker-wrapper {
    display: block !important;
  }

  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    padding: 2px 1px !important;
  }
  .react-datepicker__time-container .react-datepicker__time {
    background: none !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: $color-accent;

    &:hover {
      border-left-color: $color-accent;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: $color-accent;

    &:hover {
      border-right-color: $color-accent;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: $color-accent-hover !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $color-accent;
    transition: all 0.3s;

    &:hover {
      background-color: $color-accent-hover;
    }
  }

  .react-datepicker-popper {
    z-index: 100 !important;
    &[data-placement^="bottom"] .react-datepicker__triangle {
      border-bottom-color: $color-accent;
      // margin-top: -7px;
    }
  }
}

// .react-phone-number-input__row {
// flex-direction: row-reverse;
// @media screen and (min-width: 768px) {
//   flex-direction: row;
// }
// }

.react-phone-number-input__country--native {
}
.react-phone-number-input__icon--international {
  svg {
    fill: $color-accent;
  }
  img {
    margin-bottom: 5px;
  }
}
.react-phone-number-input__country-select-arrow {
  color: $color-accent !important;
}

.react-phone-number-input__icon {
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  box-sizing: border-box !important;
}

.react-phone-number-input__input {
  height: inherit !important;
  border-bottom: none !important;
}

.hide {
  opacity: 0;
}

.handling-request-wizard__form-label {
  position: absolute;
  top: -1rem;
  // z-index: 10;
  font-family: "Barlow Condensed", sans-serif;
  color: $color-additional;
}

.handling-request-wizard {
  .tabs__wrap {
    padding: 0 0 10px 0;
    position: relative;
    overflow-x: auto;
    max-width: calc(100vw - 20px);
    min-width: 0 !important;
    transform: rotateX(180deg);
    border-top: 1px solid $color-accent;
  }

  .nav-tabs {
    transform: rotateX(180deg);
    border-bottom: none !important;

    .disabled {
      cursor: default !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .tabs .tab-pane {
    overflow-y: auto;
  }
  .tab-pane.active {
    padding-top: 0;

    @media screen and (min-width: 768px) {
      // margin-top: 20px;
    }
  }

  .nav-item {
    display: flex;
    // flex: 1 1 0;
  }
  .nav-link {
    font-family: "Barlow Condensed", sans-serif;
    color: $color-additional !important;
    margin: 0 auto;
    &.active {
      color: black !important;
      border-bottom: 3px solid $color-accent !important;
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #000 !important;
    // background-color: #85714d;
  }
  .nav-tabs .nav-link.active:hover {
    color: #000 !important;
  }

  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #aaa;
    font-size: 1em;
  }
}

.handling-request-wizard_form-control_time {
  // .col-md-2 {
  //   @media screen and (min-width: 768px) {
  //     -webkit-flex: 0 0 20%;
  //     flex: 0 0 20%;
  //     max-width: 20%;
  //   }
  // }

  .col-1 {
    @media screen and (max-width: 768px) {
      -webkit-flex: 0 0 10%;
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
  .col-3 {
    @media screen and (max-width: 768px) {
      -webkit-flex: 0 0 30%;
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  input {
    text-align: center;
  }
}

span.handling-request-wizard_form-control_time {
  position: absolute;
  bottom: 6px;
  text-align: center;
  left: 50%;
  width: 16px;
  margin-left: -10px;
}

.react-autosuggest__container {
  position: relative;
}

// .react-autosuggest__input {
//   display: block;
//   width: 100%;
//   height: calc(1.5em + 0.75rem + 2px);
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   border-radius: 0.25rem;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  border: 1px solid $color-accent;
  background-color: #fff;
  color: black;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  -webkit-box-shadow: 0px 0px 50px -5px $color-additional;
  -moz-box-shadow: 0px 0px 50px -5px $color-additional;
  box-shadow: 0px 0px 50px -5px $color-additional;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-base-color: #fff;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: solid thin $color-accent;

  .react-autosuggest__suggestions-list &:last-child {
    border-color: transparent !important;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: $color-accent;
  color: white;
}

.react-autosuggest__input:disabled,
.form-control:disabled {
  background-color: #2f2f2f2f !important;
  color: #2f2f2f !important;
}

.suggestion {
  display: flex;
  &-additional {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 1px;
  }
}

.handling-request-soon {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: white;

  .container {
    margin: auto;
    padding: 1em;
  }

  .message {
    font-family: "Barlow Condensed", sans-serif;
    color: black;
    font-size: 1.5em;
    text-align: center;
  }

  .logo {
    width: 150px;
    height: 32px;
    margin: auto;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    display: block;
    background-image: url(../shared/img/logo/logo_light.svg);
    // @include themify($themes) {
    //   background-image: themed("logoImg");
    // }
  }
}

.handling-request-wizard__dropzone {
  border: solid 1px $color-accent !important;
  max-height: 50vh;
  min-height: 210px !important;
  padding: 5px 0;
  overflow-y: auto;
  display: block !important;
  @media screen and (max-height: 768px) {
    max-height: 40vh;
  }
  @media screen and (max-height: 600px) {
    max-height: 200px;
  }

  &:focus {
    outline: none !important;
  }

  .dropzone__input {
    margin: auto;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    left: 3px;
    top: 3px;
    min-height: 200px !important;

    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }
  .dropzone__imgs-wrapper {
    width: 100% !important;
  }

  .dropzone__drop-here {
    color: black !important;
    display: flex;
    flex-flow: column;
    span {
      margin: 0 auto;
      margin-bottom: 0.25rem;
      text-align: center;
    }
    .lnr {
      font-size: 20px;
    }
  }

  .message {
    margin-left: 0.3em;
    &:before {
      content: "Drag and drop your file here, or click to browse for it.";
      @media screen and (max-width: 768px) {
        content: "Tap to browse for your file.";
      }
    }
    &:after {
      content: "We only accept pdf-files.";
    }
  }

  .dropzone__img,
  .dropzone__pdf {
    height: 150px !important;
    width: 150px !important;
  }

  .dropzone__img {
    margin: 10px auto;
    &-name {
      color: white;
      font-weight: bold;
      padding: 0 10px;
    }
    &-delete {
      @media screen and (max-width: 768px) {
        opacity: 1 !important;
        background: #2f2f2faf;
      }
    }
  }

  .dropzone__pdf {
    display: flex;
    transition: color ease 0.4s;
    &:hover {
      color: white;
    }
    @extend .dropzone__img;
    &-name {
      @extend .dropzone__img-name;
      font-size: 1em;
    }
    &-delete {
      @extend .dropzone__img-delete;
    }
    &-title {
      font-size: 1em;
      margin: auto;
      padding: 10px;
    }
  }
}

.handling-request-wizard__notification {
  position: fixed;
  // top: 0;

  .notification__message {
    font-size: 1.5em;
  }

  .rc-notification-notice-close {
    opacity: 1;
  }
  .rc-notification-notice-close-x {
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    opacity: 0.7;
  }
}

.handling-request-wizard__form-review {
  .opened,
  .opening,
  .closed,
  .closing {
    .collapse__title {
      margin-top: 15px !important;
      margin-bottom: 5px !important;
      padding: 0 !important;
      box-shadow: none;

      @media screen and (min-width: 768px) {
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }

      p {
        line-height: 32px !important;
        font-family: "Barlow", sans-serif !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        padding: 0 !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }

    .collapse__content {
      max-width: inherit !important;
      padding: 10px 0px !important;
    }
  }
}

.handling-request-wizard__form-review__table {
  td {
    padding: 10px 0 !important;
    &.title {
      color: $color-additional !important;
    }
    &.value {
      color: black !important;
      font-weight: bold;
    }
  }
}

.notification.notification--full-wide {
  @media screen and (max-width: 768px) {
    padding: 20px 40px 20px 10px !important;
  }
}

.rc-notification-notice-close {
  @media screen and (max-width: 768px) {
    top: 10px !important;
    right: 15px !important;
  }
}

.handling-request-wizard__form-success__container {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 15px 0px;
  @media screen and (min-width: 768px) {
    height: 100%;
    min-height: 100%;
  }
  .handling-request-wizard__form-success__container-content {
    margin: auto;
    width: 70%;
    padding: 10px;
    padding-top: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    .headline {
      line-height: 32px;
      font-size: 32px;
      font-family: "Barlow", sans-serif;
    }
    .tagline {
      margin-top: 15px;
      line-height: 20px;
      font-size: 20px;
      font-family: "Barlow", sans-serif;
      color: $color-additional;
    }
  }
}

.handling-request-wizard__form-review__container {
  padding-bottom: 15px;
  border-bottom: 1px solid black;
}

.handling-request-wizard__form-review__legal {
  margin: 0 auto;
  margin-bottom: 50px;
  a {
    color: $color-accent;
  }
}

.handling-request-wizard__form-billing {
  margin: 20px auto;
  a {
    color: $color-accent;
  }
}
