#root {
  height: 100%;
}

body {
  font-family: Source Sans Pro, sans-serif !important;
}

.bg-gold {
  background-color: #85714d !important;
}

.border-gold {
  border-color: #85714d !important;
  border-width: 0.1rem !important;
}

.border-white {
  border-color: white !important;
  border-width: 0.1rem !important;
}

.btn-gold {
  background-color: #85714d !important;
  border-color: #85714d !important;
  color: #fff !important;
  display: flex;
}
.btn-sm.btn-gold {
  width: 50px;
  height: 50px;
}
.btn-gold > svg {
  margin: auto;
}

.btn-gold-invert {
  background-color: #fff !important;
  border-color: #85714d !important;
  color: #85714d !important;
}

.text-gold {
  color: #85714d !important;
}

.border-gold-1 {
  border-color: #85714d !important;
  border-width: 0.1rem !important;
  border-style: solid !important;
}

.border-gold-2 {
  border-color: #85714d !important;
  border-width: 0.25rem !important;
  border-style: solid !important;
}

.react-bs-container-footer,
.react-bs-container-header {
  background-color: #85714d !important;
}

/* .react-bs-container-footer,
.react-bs-container-header th {
} */

.react-bs-table-bordered {
  border-color: #85714d !important;
}

.react-bootstrap-table-header-title {
  color: white !important;
  font-size: 2rem;
  text-align: center !important;
  border-style: solid !important;
  border-color: #85714d !important;
  border-width: 0.1rem !important;
}

.react-bootstrap-table-header-column {
  color: #85714d !important;
  font-size: 1rem;
  text-align: center !important;
  background-color: white !important;
  border-bottom-style: solid !important;
  border-bottom-color: #85714d !important;
  border-bottom-width: 0.1rem !important;
  border-top-style: solid !important;
  border-top-color: #85714d !important;
  border-top-width: 0.1rem !important;
}

.react-bootstrap-table-header-column:first-child {
  border-left-style: solid !important;
  border-left-color: #85714d !important;
  border-left-width: 0.1rem !important;
}

.react-bootstrap-table-header-column:nth-child(even) {
  border-left-style: solid !important;
  border-left-color: #85714d !important;
  border-left-width: 0.1rem !important;
  border-right-style: solid !important;
  border-right-color: #85714d !important;
  border-right-width: 0.1rem !important;
}

.react-bootstrap-table-header-column:last-child {
  border-right-style: solid !important;
  border-right-color: #85714d !important;
  border-right-width: 0.1rem !important;
}

.table-bordered td,
.table-bordered th {
  border: none;
}

.react-bs-container-body tr {
  cursor: pointer;
}

td[class*="MuiTableCell-root-"],
th[class*="MuiTableCell-root-"] {
  padding: 0 !important;
}
td[class*="MuiTableCell-root-"]:first-child,
th[class*="MuiTableCell-root-"]:first-child {
  padding-left: 15px !important;
}

.form-control {
  color: #222 !important;
  background-color: #fff !important;
}
.form-control:focus {
  border-color: #85714d;
  box-shadow: 0 0 0 0.2rem rgba(52, 44, 30, 0.25);
}

.table-hover tbody tr:hover {
  background-color: #85714d !important;
  color: white !important;
}

a.btn-download {
  font-size: 1.25em;
  color: #85714d;
  line-height: 1.15;
  padding: 0;
}

button.btn-download {
  font-size: 1.25em;
  color: #85714d;
  border: none;
  background: none;
  line-height: 1.15;
  padding: 0;
}

.pagination .page-item.active .page-link {
  color: #222 !important;
}

/* .pagination li.pagination__item.page-item.disabled button.pagination__link.pagination__link--arrow.page-link svg {
  fill: transparent;
} */

.drag-handle {
  display: none;
}
