.flights-table-title {
  font-size: 2rem;
}

.flights-table-actions {
  font-size: 1.5rem;
}

.flights-table {
  overflow-x: auto !important;
  flex: 1 1 0;
  padding: 1px;
}

.react-calendar {
  /* width: auto !important; */
  font-size: 1rem;
  /* min-width: 350px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px; */
}
@media only screen and (max-width: 575px) {
  .react-calendar {
    width: auto !important;
  }
}

.react-calendar__tile--active {
  background-color: #85714d !important;
  color: #fff !important;
}

.MuiPaper-root-1 {
  background-color: transparent !important;
}

.react-calendar__tile--hasActive {
  background-color: #85714d !important;
  color: white !important;
  border: none !important;
}

div[class*="MuiPaper-elevation2-"] {
  box-shadow: none !important;
}

td[class*="MuiTableCell-root-"],
th[class*="MuiTableCell-root-"] {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.react-bs-table-tool-bar .btn-group {
  margin-bottom: 10px !important;
}

.segmented-control button {
  transition: ease-in-out 0.25s;
}

.segmented-control button.selected,
.segmented-control button.selected:focus,
.segmented-control button.selected:active,
.segmented-control button.selected:hover {
  background-color: #85714d;
  color: white;
}

.segmented-control.btn-toolbar:last-child {
  margin-bottom: 10px !important;
}

.badge-secondary {
  background-color: #85714d !important;
  color: white;
  font-size: 1rem;
}

.btn.btn-outline-secondary,
.btn.btn-outline-secondary:before,
.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary:active:focus {
  background: transparent;
  border-color: #85714d !important;
  color: black;
}
