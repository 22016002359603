body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  height: 100vh;
}

.navbar {
  padding: 0 !important;
}
.navbar-brand {
  margin-right: 0 !important;
}
.navbar-toggler {
  background-color: #85714d !important;
  border-color: #85714d !important;
  border-style: solid !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.navbar-light .navbar-nav .nav-link {
  color: black !important;
}

.form-check-label {
  display: inline;
}