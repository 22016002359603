.load {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212222;
  pointer-events: none;
  opacity: 1;
  transition: ease opacity 0.25s;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease-in-out loaded 0.5s;
  }
}

.load__icon {
  fill: white;
  margin: auto;
  width: auto;
  height: 44px;
  position: absolute;
  background: transparent;
  z-index: 1;
  clip-path: none;
  transition: ease-in-out 0.25s;

  &.animate {
    animation: linear load 2s infinite;
    animation-delay: 0.9s;
    animation-timing-function: cubic-bezier(1, 0.25, 0, 0.75);
    animation-fill-mode: both;
  }

  &.stroke {
    fill: #937449;
    z-index: 1;
    clip-path: none;
  }
}

.load__icon-flat {
  fill: #2f2f2f;
  margin: auto;
  width: auto;
  height: 44px;
  position: absolute;
}

.load__icon__background {
  margin: auto;
  width: auto;
  height: 50px;
  position: absolute;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1500 308.178'%3E%3Cpath class='name' fill='%232F2F2F' d='M877.254,238.747L849.993,63.041l-27.688,175.706l-10.861,67.088h-48.134L819.111,4.686h62.828l56.865,301.149h-50.688L877.254,238.747z'%3E%3C/path%3E%3Cpath class='stroke' fill='%232F2F2F' d='M979.483,172.299H167.826l59.421,12.565H1276.8l55.374-12.565H979.483z'%3E%3C/path%3E%3Cpath class='stroke' fill='%232F2F2F' d='M979.483,123.313v-0.213H0l62.189,13.205h917.294v-0.213l458.327,0.213L1500,123.101L979.483,123.313z'%3E%3C/path%3E%3Cpath class='stroke' fill='%232F2F2F' d='M917.72,147.806H83.913l62.189,12.992h1207.795l62.189-12.992H917.72z'%3E%3C/path%3E%3Cpath class='name' fill='%232F2F2F' d='M819.111,4.473h62.828l56.865,301.362h-50.901l-10.649-67.088L849.993,62.828 M384.424,304.771V3.407h49.837l63.255,186.355V3.407h45.577v301.363h-47.281l-65.81-195.939v195.939H384.424z M688.556,110.961v-42.17c0-18.954-10.648-25.77-25.344-25.77s-25.345,6.815-25.345,25.77V89.45c0,15.547,5.111,22.362,19.381,30.456l35.354,19.807c28.752,16.399,46.003,29.604,46.003,61.977v43.447c0,46.429-28.752,62.828-74.542,62.828h-0.852c-45.577,0-74.542-15.973-74.542-62.828v-52.393h48.984v45.79c0,18.529,10.649,26.622,25.771,26.622s25.771-8.093,25.771-26.622v-23.854c0-15.547-4.686-23.214-19.807-31.521l-34.716-19.594c-29.178-16.825-46.003-30.456-46.003-61.977V61.977C588.67,17.251,621.468,0,662.785,0h0.853c41.317,0,74.116,17.251,74.116,61.977v48.984H688.556L688.556,110.961z M1121.113,244.498c0,46.429-30.243,63.68-75.82,63.68h-0.852c-45.577,0-75.82-16.825-75.82-63.68V63.68C968.834,18.955,998.864,0,1044.654,0h0.852c45.577,0,75.82,18.955,75.82,63.68v42.596h-50.05V71.347c0-21.084-10.436-27.474-26.196-27.474c-15.76,0-26.196,6.39-26.196,27.474v165.271c0,21.085,10.437,27.475,26.196,27.475c15.761,0,26.196-6.39,26.196-27.475v-38.336h49.837V244.498z'%3E%3C/path%3E%3C/svg%3E%0A");
}

.load__icon-wrap {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes load {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  25% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes loaded {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// @keyframes logo-clip {
//   0% {
//     clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//   }
//   50% {
//     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//   }
//   to {
//     clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
//   }
// }
// .transition {
//   position: fixed;
//   z-index: 100;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 0;
//   background-color: #212222;
//   transition: opacity 0.65s 0.35s;
//   pointer-events: none;
// }
// body.loading .transition {
//   opacity: 1;
//   transition: opacity 0.4s 0.55s;
// }
// .transition__content {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .transition__logo {
//   z-index: 1;
//   clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
// }
// body.loading .transition__logo {
//   animation: logo-clip 1.45s;
//   animation-delay: 0.9s;
//   animation-timing-function: cubic-bezier(1, 0.25, 0, 0.75);
//   animation-fill-mode: both;
// }
// .transition__logo {
//   position: absolute;
// }

// /*! CSS Used from: https://www.nsac.aero/build/css/style-9e8475d4e3f4185c10f0.css */
// *{box-sizing:border-box;margin:0;padding:0;font-size:100%;font-style:inherit;}
// *{font-weight:inherit;}
// :focus{outline:none;}
// .logo{display:block;width:8rem;padding:.16667rem;border:1px solid transparent;}
// @media screen and (min-width:750px){
// .logo{width:10rem;}
// }
// @media screen and (min-width:1280px){
// .logo{width:12rem;}
// }
// .logo svg .stroke{transform:scaleX(1);transform-origin:center center;transition:transform .5s cubic-bezier(.77,0,.175,1) .15s;}
// .logo:focus{border:1px dashed #937449;}
// .logo:active,.logo:hover{border:1px solid transparent;}
// .logo:active svg .stroke,.logo:hover svg .stroke{transform:scaleX(.92);}
// .logo--flat svg>*{fill:#2f2f2f;}
