.airport-input {
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: calc(1.5em + 0.75rem + 2px);
    width: 100%;
    border: 1px solid #aaa;
    background-color: #ddd;
    color: black;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 200px;
    overflow-y: scroll;
    scrollbar-base-color: #fff;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: solid thin white;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #85714d;
    color: white;
  }

  .react-autosuggest__input:disabled,
  .form-control:disabled {
    background-color: #2f2f2f2f !important;
    color: #2f2f2f !important;
    // border-color: #2f2f2f !important;
  }
}
